module.exports = {
  devEnv:"online",/*dev:开发版本，online：线上版本*/
  baseURL1:"http://192.168.0.101:1231/",/*测试-vue"http://dev_test.wwei.cn"*/ //http://192.168.0.102:1234
  baseURL:"",/*正式*/
  baseMtURL1:"http://192.168.0.102:1234",/*测试-移动端预览*/
  baseMtURL:"",/*正式-移动端预览*/
  baseJmURL:"https://jiema.wwei.cn",/*解码接口*/
  upgradeUrls:"https://denglu.wwei.cn/price",/*升级会员vip*/ 
  upgradeUrl:"https://denglu.wwei.cn/price",/*升级会员vip*/ 
  memberIdentityUrl:"https://denglu.wwei.cn/user/memberIdentityRule",/*跳转后台身份管理*/ 
  memberDepartmentUrl:"https://denglu.wwei.cn/user/memberDepartmentRule",/*跳转后台部门管理*/
  mianDomain:'https://www.wwei.cn',//主域名
  dengluDomain:'https://denglu.wwei.cn',//个人中心域名
  editDomain:'https://bianji.wwei.cn',//个人中心域名
  url:{
  	userInfo:"https://denglu.wwei.cn/user/userinfo",/*后台用户设置*/
  	geditDomian:"https://bianji.wwei.cn/gedit",/*跳转高级编辑器*/
	videosys:"https://shipin.wwei.cn/media",/*视频刷一刷*/
	videobfq:"https://shipin.wwei.cn/qrcode-video",/*视频播放器*/
	audiobfq:"https://yinpin.wwei.cn/music",/*音频播放器*/
	company_index:"https://denglu.wwei.cn/user/companyIndex",/*企业主页管理*/
  },
  apiAppid:"fgmdjdowqy6eyi0g",/*api请求appid*/
  apiAppkey:"lrrqiz1p2pf7vz8b5kyinxtop32tvd8n",/*api请求appkey*/
  qiniuMediaDomain:"//view-nc.mbm02.cn",/*七牛云注册的空间域名*/
  qiniuTimeKey:'yij5j88h2iguhhch5sdwrzcejaumdyn8ywsy',//七牛云时间防盗链备用key
  aliMainKey:'o6W5KVMevCRvBKf9c7pHIA0Xnumm0wPF',/*阿里云加密主key*/
  aliOSS:{
	region: 'oss-cn-shenzhen',
	accessKeyId: 'LTAID2mtjY1XqJWm',
	accessKeySecret: '7THnyDvrZAJjbXbP8tJ0Uvi9ncFTDM',
	bucket: 'wweicode',
	outDomain:'//net-img.popziti.com',
  },
  aliOSSOther:{
  	region: 'oss-cn-hangzhou',
  	accessKeyId: 'G2cBC9V3TTChoX3L',
  	accessKeySecret: 'tYAKhgm2VdIw4O4GW7ysLROGyEAbjQ',
  	bucket: 'tipian-attach',
  	outDomain:'//imgfile.tipian01.cn',
	secure:true,
  }
}